import React from 'react';
import { renderInnovation} from 'route/Landing/sections/02_Innovation'
import Layout from "../components/Layout"
import 'styles/pages/_HowTo.scss';

export default function HowToUseIt({location}){
  return (
    <Layout location={location} t="default">
      <div className="page-how-to fade-in">
        {renderInnovation({t: 'default', showLearnMore: false })}
      </div>
    </Layout>
  )
}